<template>
  <v-row>
    <v-col cols="12">
      <v-card class="pt-2 mb-0 pb-0">
        <v-row class="pt-2 mx-2 pt-0">
          <v-col
            cols="12"
            class="px-4 pt-2 mt-1 pb-0 mb-0"
          >
            <v-select
              v-model="selectedTransactionFilter"
              outlined
              dense
              :items="transaction_types"
              label="Filter Transactions By"
            ></v-select>
          </v-col>

          <v-col
            cols="5"
            xs="5"
            class="text-left mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedStartDate"
              dense
              type="date"
              label="Start Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="5"
            xs="5"
            class="text-right mt-0 pt-0"
          >
            <v-text-field
              v-model="selectedEndDate"
              dense
              type="date"
              label="End Date"
            ></v-text-field>
          </v-col>
          <v-col
            cols="2"
            xs="2"
            class="text-right mt-0 pt-0"
          >
            <spinner v-if="loading" class="text-right"></spinner>
            <v-btn
              v-else
              icon small
              class="primary"
              @click="getProfits()"
            >
              <font-awesome-icon icon="fa-solid fa-search"/>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title class="">
          <span class="me-3">Profits ({{ profits_total }} frs)</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items=" profitsList" item-key="id" class="table-rounded"
          :items-per-page="10" disable-sort :search="search">
          <!-- name -->
          <template #[`item.username`]="{item}">
            <div class="d-flex flex-column" style="cursor: pointer">
              <span class="d-block font-weight-semibold text--primary text-truncate">{{ item.username }}</span>
              <small >{{ item.name }}</small>
            </div>
          </template>
          <template #[`item.amount`]="{item}">
            {{ item.amount }} 
          </template>
          <template #[`item.profit`]="{item}">
            {{ item.profit }} F CFA
          </template>
          <template #[`item.date_time`]="{item}">
            {{ item.date_time }}
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiSquareEditOutline, mdiDotsVertical } from '@mdi/js'
import axios from 'axios'
import Spinner from "@/components/Spinner.vue";

export default {
  components: {
    Spinner,
  },

  data() {

    return {
      headers: [
        { text: 'Name', value: 'username' },
        { text: 'Amount', value: 'amount' },
        { text: 'Profit', value: 'profit' },
        { text: 'Date/Time', value: 'date_time' },
      ],
      profitsList: [],
      search: '',
      selectedStartDate: '',
      selectedEndDate: '',
      selectedTransactionFilter: '',
      transaction_types: ["Payment Fees", "Delivery Fees", "Item Sales"],
      loading: false,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
      },
    }
  },

  mounted(){
      // executes these after the page has been mounted
      document.title = "Manager: Market Place | List of Profits"
      this.getTopUps()
  },

  methods:{
    async getProfits(){
      if (this.selectedStartDate === ''){
        this.selectedStartDate = 'null'
      }
      if (this.selectedEndDate === ''){
        this.selectedEndDate = 'null'
      }
      if (this.selectedTransactionFilter === ''){
        this.selectedTransactionFilter = 'All'
      }
      this.loading = true
      console.log(this.loading)
      await axios
        .get('/api/v1/manager/market_place/get/profits/'+this.selectedTransactionFilter+'/'+this.selectedStartDate+'/'+this.selectedEndDate+'/')
        .then(response => {
          this.profitsList = response.data['profits']  // get the data and fill into campaigns
          this.profits_total = response.data['profits_total']
          console.log(this.profitsList)
        })
        .catch(error => {
          if (error.response){
            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }else{
            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
            this.$store.commit('setSnackBarColor', "red darken-4")
            this.$store.commit('activateSnackBar', true)
          }
        })
      this.loading = false
    },

  },
}
</script>
