var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-2 mb-0 pb-0"},[_c('v-row',{staticClass:"pt-2 mx-2 pt-0"},[_c('v-col',{staticClass:"px-4 pt-2 mt-1 pb-0 mb-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.transaction_types,"label":"Filter Transactions By"},model:{value:(_vm.selectedTransactionFilter),callback:function ($$v) {_vm.selectedTransactionFilter=$$v},expression:"selectedTransactionFilter"}})],1),_c('v-col',{staticClass:"text-left mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"Start Date"},model:{value:(_vm.selectedStartDate),callback:function ($$v) {_vm.selectedStartDate=$$v},expression:"selectedStartDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"5","xs":"5"}},[_c('v-text-field',{attrs:{"dense":"","type":"date","label":"End Date"},model:{value:(_vm.selectedEndDate),callback:function ($$v) {_vm.selectedEndDate=$$v},expression:"selectedEndDate"}})],1),_c('v-col',{staticClass:"text-right mt-0 pt-0",attrs:{"cols":"2","xs":"2"}},[(_vm.loading)?_c('spinner',{staticClass:"text-right"}):_c('v-btn',{staticClass:"primary",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.getProfits()}}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-search"}})],1)],1)],1),_c('v-card-title',{},[_c('span',{staticClass:"me-3"},[_vm._v("Profits ("+_vm._s(_vm.profits_total)+" frs)")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.profitsList,"item-key":"id","items-per-page":10,"disable-sort":"","search":_vm.search},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-column",staticStyle:{"cursor":"pointer"}},[_c('span',{staticClass:"d-block font-weight-semibold text--primary text-truncate"},[_vm._v(_vm._s(item.username))]),_c('small',[_vm._v(_vm._s(item.name))])])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+" ")]}},{key:"item.profit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.profit)+" F CFA ")]}},{key:"item.date_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date_time)+" ")]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }